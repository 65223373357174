import { Link } from '@remix-run/react';
import type { RemixLinkProps } from '@remix-run/react/dist/components';

import type { ButtonHTMLAttributes, ReactNode } from 'react';
import { useHydrated } from 'remix-utils/use-hydrated';

import { cn } from '~/utils/css';

import { Spinner } from '../common/spinner';

type BaseButtonAttributes = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  mode?: 'brand' | 'secondary' | 'error' | 'plain';
  useDark?: boolean;
};

export type ButtonProps =
  | (BaseButtonAttributes & RemixLinkProps)
  | (BaseButtonAttributes & ButtonHTMLAttributes<HTMLButtonElement>);

const sizeClasses: Record<
  Extract<BaseButtonAttributes['size'], string>,
  string
> = {
  xs: 'rounded px-2 py-1 text-xs',
  sm: 'rounded px-2 py-1 text-sm',
  md: 'rounded-md px-2.5 py-1.5 text-sm',
  lg: 'rounded-md px-3 py-2 text-sm',
  xl: 'rounded-md px-3.5 py-2.5 text-sm',
};

const modeClasses: Record<
  Extract<BaseButtonAttributes['mode'], string>,
  string
> = {
  brand:
    'bg-brand-500 text-white border border-brand-500 dark:bg-brand-700 dark:text-zinc-50 dark:ring-offset-zinc-800 hover:bg-brand-600',
  secondary:
    'bg-white text-gray-900 border border-gray-300 ring-gray-300 dark:bg-zinc-700 dark:border-zinc-600 dark:ring-offset-zinc-800 dark:ring-zinc-800 dark:text-zinc-50 dark:hover:bg-zinc-600 hover:bg-gray-50 disabled:hover:bg-white',
  error:
    'bg-red-600 text-white border border-red-600 hover:bg-red-700 hover:border-red-700 focus:ring-red-600 hover:focus:ring-red-700 disabled:hover:bg-red-600 disabled:hover:border-red-600',
  plain: 'text-zinc-900 shadow-none hover:bg-zinc-100',
};

const baseClasses =
  'font-medium inline-flex items-center justify-center shadow-sm dark:focus:ring-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 disabled:opacity-60';

export function Button(props: ButtonProps) {
  const { size = 'md', mode = 'secondary', children, className } = props;

  const classes = cn(
    baseClasses,
    sizeClasses[size],
    modeClasses[mode],
    className
  );

  // Render the button as a link if we have the `to` prop applied, otherwise
  // render the button as a `button` element.
  return 'to' in props ? (
    <Link {...props} className={classes}>
      {children}
    </Link>
  ) : (
    <button {...props} className={classes}>
      {children}
    </button>
  );
}

type ButtonLabelProps = {
  isSubmitting?: boolean;
  children:
    | ReactNode
    | (({ isSubmitting }: { isSubmitting: boolean }) => ReactNode);
};

export function ButtonLabel(props: ButtonLabelProps) {
  if (typeof props.children !== 'function') {
    return props.children;
  }

  return (
    <>
      {props.isSubmitting ? (
        <span className='flex'>
          <Spinner />
          {props.children({ isSubmitting: props.isSubmitting ?? false })}
        </span>
      ) : (
        props.children({ isSubmitting: props.isSubmitting ?? false })
      )}
    </>
  );
}

type HydratedButtonProps = BaseButtonAttributes & {
  to: string;
  onClick: () => void;
  children: ReactNode;
};

export function HydratedButton(props: HydratedButtonProps) {
  const { size, mode, to, onClick, children } = props;

  const hydrated = useHydrated();

  if (!hydrated) {
    return (
      <Button size={size} mode={mode} to={to}>
        {children}
      </Button>
    );
  }

  return (
    <Button type='button' size={size} mode={mode} onClick={onClick}>
      {children}
    </Button>
  );
}
